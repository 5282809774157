<template>
  <div>
    <div class="d-flex justify-content-end align-content-center">
      <div class="mb-5">
        <b-button
          class="font-weight-bolder"
          variant="light-primary"
          @click="$router.push({ name: 'staffAttendanceExport' })"
          >Details
        </b-button>
      </div>
    </div>

    <EmployeeMonthlyAttendanceTable
      :items="items"
      :pagination="pagination"
      :is-busy="isBusyStore"
      @changePage="handlePageChange"
      @showIndividualAttendance="openMonthlyModal"
      @leave-count-clicked="showLeaveModal"
      @away-count-clicked="showAwayModal"
    >
    </EmployeeMonthlyAttendanceTable>

    <StaffIndividualMonthlyAttendanceModal
      :employee="employee"
      :date="date"
      :date-info="dateInfo"
    ></StaffIndividualMonthlyAttendanceModal>

    <!-- begin:: staff employee away modal    -->
    <SchoolEmployeeIndividualAwayModal
      :aways="aways"
      :is-busy="isBusy"
      :pagination="awayPagination"
      @page-change="handleAwayChange"
    >
    </SchoolEmployeeIndividualAwayModal>
    <!-- end:: staff employee away modal    -->

    <!-- begin:: staff employee leave modal    -->
    <SchoolEmployeeIndividualLeaveModal
      :leaves="leaves"
      :is-busy="isBusy"
      :pagination="leavePagination"
      @page-change="handleLeavePage"
    ></SchoolEmployeeIndividualLeaveModal>
    <!-- end:: staff employee leave modal    -->
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import EmployeeMonthlyAttendanceTable from "@/modules/school/components/shared/management/attendance/table/EmployeeMonthlyAttendanceTable";
import StaffIndividualMonthlyAttendanceModal from "@/modules/school/components/management/attendance/staff/StaffIndividualMonthlyAttendanceModal";
import SchoolEmployeeIndividualLeaveModal from "@/modules/school/components/management/attendance/SchoolEmployeeIndividualLeaveModal";
import SchoolEmployeeIndividualAwayModal from "@/modules/school/components/management/attendance/SchoolEmployeeIndividualAwayModal";

export default {
  name: "StaffMonthlyAttendance",
  components: {
    SchoolEmployeeIndividualAwayModal,
    SchoolEmployeeIndividualLeaveModal,
    StaffIndividualMonthlyAttendanceModal,
    EmployeeMonthlyAttendanceTable,
  },
  data() {
    return {
      isBusy: false,
      employee: {},
      date: new Date(dayjs().format()),
      dateInfo: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    /**
     * Fetch monthly attendance from store.
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchSchoolStaffMonthlyAttendance")
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    /**
     * @param data (year & month)
     */
    filterDate(data) {
      console.log("Date ", data);
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: data.year,
        month: data.month,
      });

      this.$store.dispatch("changeSchoolStaffMonthlyAttendanceDate", {
        date: date.format(),
      });

      this.fetch();
    },

    /**
     * Change page
     *
     * @param page
     */
    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setSchoolStaffMonthlyAttendancePage", page);
      this.fetch();
    },

    /**
     * Open Modal
     *
     * @param data
     */
    openMonthlyModal(data) {
      const item = data.item;
      console.log("Selected item ", item);
      this.employee = item;
      console.log("Checking employee", this.employee);
      this.date = this.dateFiler
        ? new Date(this.dateFiler)
        : new Date(dayjs().format());

      this.$store.commit("setStaffIndividualAttendancePage", 1);

      // Fetch
      this.$store.dispatch("fetchStaffIndividualAttendance", {
        id: this.employee.id,
        date_type: this.dateType,
        date: this.dateFiler,
        from_date: this.filterDate.from_date,
        to_date: this.filterDate.to_date,
      });

      this.dateInfo = {
        date: this.date,
        date_type: this.dateType,
        from_date: this.filterDate.from_date,
        to_date: this.filterDate.to_date,
      };

      this.$bvModal.show("employee-monthly-attendance-modal");
    },

    /**
     * Show school employee leave modal.
     *
     * @param data
     */
    showLeaveModal(data) {
      console.log(data);

      this.$store.dispatch("changeIndividualStaffLeaveListFilter", {
        date: this.dateFiler,
        staffId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualStaffLeaveList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-leave-modal");
    },

    /**
     * Handle leave page.
     *
     * @param page
     */
    handleLeavePage(page) {
      console.log(page);

      this.$store.commit("setIndividualStaffLeaveListPage", page);
      this.$store.dispatch("fetchIndividualStaffLeaveList");
    },

    /**
     * Show school employee leave modal.
     *
     * @param data
     */
    showAwayModal(data) {
      console.log(data);

      this.$store.dispatch("changeIndividualStaffAwayListFilter", {
        date: this.dateFiler,
        staffId: data.id,
      });

      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualStaffAwayList")
        .finally(() => (this.isBusy = false));
      this.$bvModal.show("school-employee-away-modal");
    },

    /**
     * Handle away page change.
     *
     * @param page
     */
    handleAwayChange(page) {
      console.log("Page : ", page);

      this.$store.commit("setIndividualStaffAwayListPage", page);
      this.$store.dispatch("fetchIndividualStaffAwayList");
    },
  },
  computed: {
    ...mapGetters({
      items: "getSchoolStaffMonthlyAttendanceList",
      pagination: "getSchoolStaffMonthlyAttendancePagination",
      dateFiler: "getSchoolStaffMonthlyListDate",
      leaves: "getIndividualStaffLeaveList",
      aways: "getIndividualStaffAwayList",
      leavePagination: "getIndividualStaffLeaveListPagination",
      awayPagination: "getIndividualStaffAwayListPagination",
      isBusyStore: "getSchoolStaffMonthlyListBusy",
      filterDate: "getSchoolStaffMonthlyListFilter",
      dateType: "getSchoolStaffMonthlyListDateType",
    }),
  },
};
</script>

<style scoped></style>
