<template>
  <div>
    <IndividualEmployeeMonthlyAttendanceModal
      :items="items"
      :date="date"
      :employee="employee"
      :records="records"
      :is-busy="fetchBusy"
      :title="title"
      :pagination="pagination"
      @showClock="openClock"
      @showManageModal="openManage"
      @showUpdateModal="showUpdateModal"
      @changePage="handlePageChange"
    >
    </IndividualEmployeeMonthlyAttendanceModal>
    <StaffAttendanceManagementModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="handleFormSuccess"
    ></StaffAttendanceManagementModal>

    <ClockRecordModal :is-busy="isBusy" :records="records"></ClockRecordModal>

    <StaffAttendanceUpdateTimeModal
      :employee="employee"
      :attendance="att"
      @formSubmitted="handleFormSuccess"
    ></StaffAttendanceUpdateTimeModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import StaffAttendanceManagementModal from "@/modules/school/components/management/attendance/staff/StaffAttendanceManagementModal";
import ClockRecordModal from "@/modules/core/components/attendance/ClockRecordModal";
import StaffAttendanceUpdateTimeModal from "@/modules/school/components/management/attendance/staff/StaffAttendanceUpdateTimeModal";
import IndividualEmployeeMonthlyAttendanceModal from "@/modules/school/components/shared/management/attendance/modal/IndividualEmployeeMonthlyAttendanceModal";

export default {
  name: "StaffIndividualMonthlyAttendanceModal",
  components: {
    IndividualEmployeeMonthlyAttendanceModal,
    StaffAttendanceUpdateTimeModal,
    ClockRecordModal,
    StaffAttendanceManagementModal,
  },
  props: {
    employee: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: 1,
        };
      },
    },
    date: {
      type: Date,
      required: true,
      default: new Date(dayjs().format()),
    },
    dateInfo: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isBusy: false,
      attendance: {},
      att: {},
      title: "Staff Monthly Attendance",
    };
  },
  methods: {
    openClock(data) {
      console.log(data);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk(
          "Staff has to be checked in first for their clock records to appear",
          {
            title: "Attendance is empty",
            centered: true,
          }
        );
        return null;
      }

      this.isBusy = true;
      this.$bvModal.show("clock-record-modal");

      this.$store
        .dispatch("fetchStaffClockRecord", {
          id: this.employee.id,
          date: data.item.attendance.date,
        })
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    openManage(data) {
      console.log(data);

      // this.employee = data.item;
      console.log("Employee", this.employee);

      const item = {};

      item.status = data.item.status;

      item.date = data.item.date;

      if (data.item.attendance) item.attendance = data.item.attendance;

      this.attendance = item;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-manage-modal");
    },

    showUpdateModal(data) {
      console.log(data);

      // this.employee = data.item;
      console.log("Employee", this.employee);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk("Staff doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      this.att = null;

      this.att = {
        id: data.item.id,
        date: data.item.date,
        in_fmt: data.item.attendance.time_in_fmt,
        out_fmt: data.item.attendance.time_out_fmt,
      };
      console.log("Attendance", this.att);

      this.$bvModal.show("common-attendance-update-time-modal");
    },

    /**
     * Handle attendance entry form submission success.
     *
     * @param form
     */
    handleFormSuccess(form) {
      console.log("Form submitted", form);

      this.fetch();

      this.$store.dispatch("fetchSchoolStaffMonthlyAttendance");
    },

    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setStaffIndividualAttendancePage", page);
      this.fetch();
    },

    fetch() {
      this.$store.dispatch("fetchStaffIndividualAttendance", {
        id: this.employee.id,
        date: this.dateInfo.date,
        date_type: this.dateInfo.date_type,
        from_date: this.dateInfo.from_date ?? dayjs().format(),
        to_date: this.dateInfo.to_date ?? dayjs().format(),
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffIndividualAttendanceList",
      pagination: "getStaffIndividualAttendanceListPagination",
      records: "getStaffClockRecordList",
      fetchBusy: "getStaffIndividualAttendanceListBusy",
    }),
  },
};
</script>

<style scoped></style>
